// src/data/level_outcome.js

import level1Image from '../assets/images/outcome/level_1.jpg';
import level2Image from '../assets/images/outcome/level_2.jpg';
import level3Image from '../assets/images/outcome/level_3.jpg';
import level4Image from '../assets/images/outcome/level_4.jpg';
import level5Image from '../assets/images/outcome/level_5.jpg';
import level6Image from '../assets/images/outcome/level_6.jpg';
import level7Image from '../assets/images/outcome/level_7.jpg';
import level8Image from '../assets/images/outcome/level_8.jpg';
// Import more images as needed

const levelOutcomes = {
  1: {
    message: "Congrats! You're promoted to SDR!",
    image: level1Image,
  },
  2: {
    message: "Awesome! You're now a BDR!",
    image: level2Image,
  },
  3: {
    message: "Great job! You're advancing to AE!",
    image: level3Image,
  },
  4: {
    message: "Fantastic! You've been promoted to Senior AE!",
    image: level4Image,
  },
  5: {
    message: "Excellent! Welcome to the Account Manager team!",
    image: level5Image,
  },
  6: {
    message: "Impressive! You've become a Sales Manager!",
    image: level6Image,
  },
  7: {
    message: "Outstanding! You're now a bonafide Marc Benioff",
    image: level7Image,
  },
  8: {
    message: "You beat all levels -- You're a master like Stephen Murphy",
    image: level8Image,
  },
  // Add more levels as needed
};

export default levelOutcomes;
