// src/componenets/LevelOutcome.jsx
import React from 'react';
import PropTypes from 'prop-types';
import levelOutcomes from '../data/level_outcome';

const LevelOutcome = ({ level, onContinue }) => {
  const outcome = levelOutcomes[level];

  if (!outcome) {
    return null; // Handle cases where outcome data might be missing
  }

  return (
    <div className="mt-4 flex flex-col items-center space-y-4 p-6 bg-gradient-to-r from-green-100 to-green-200 rounded-lg shadow-lg">
      <div className="text-green-800 font-bold text-2xl text-center">
        {outcome.message}
      </div>
      <img
        src={outcome.image}
        alt={`Level ${level} Completed`}
        className="w-40 h-40 object-contain rounded-full border-4 border-green-500 shadow-md"
      />
      <button
        onClick={onContinue}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-full transition duration-300"
      >
        Continue
      </button>
    </div>
  );
};

LevelOutcome.propTypes = {
  level: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default LevelOutcome;
