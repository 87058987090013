// src/index.jsx
import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import './styles/index.css'; // TailwindCSS
import App from './App';
import reportWebVitals from './reportWebVitals'; // Optional

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container); // Create root

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional: If you have performance metrics to report
reportWebVitals();
