// /src/data/levels.js

const levels = [
  { level: 1, rows: 5, cols: 5, deals: 2 },
  { level: 2, rows: 6, cols: 6, deals: 3 },
  { level: 3, rows: 6, cols: 6, deals: 4 },
  { level: 4, rows: 7, cols: 7, deals: 4 },
  { level: 5, rows: 7, cols: 7, deals: 5 },
  { level: 6, rows: 7, cols: 7, deals: 5 },
  { level: 7, rows: 8, cols: 8, deals: 6 },
  { level: 8, rows: 8, cols: 8, deals: 7 },
  // Add more levels as needed
];

export default levels;
