// src/components/GameBoard.jsx

import React, { useState, useEffect, useCallback } from 'react';
import Cell from './Cell';
import Header from './Header';
import WelcomeScreen from './WelcomeScreen';
import Leaderboard from './Leaderboard';
import { db } from '../firebase/firebase';
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  orderBy,
  limit,
  where,
} from 'firebase/firestore';
import LevelOutcome from './LevelOutcome';
import levels from '../data/levels';
import { TailSpin } from 'react-loader-spinner';
import { getOrCreateUsername } from '../utils/usernameManager';

const GameBoard = () => {
  // **Game State Management**
  const [gameState, setGameState] = useState('welcome'); // 'welcome', 'playing', 'leaderboard'
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
  const [board, setBoard] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [flags, setFlags] = useState(0);
  const [firstClick, setFirstClick] = useState(true);
  const [timer, setTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);

  // **Leaderboard and User Stats**
  const [leaderboard, setLeaderboard] = useState([]);
  const [userPosition, setUserPosition] = useState(null);
  const [userTime, setUserTime] = useState(null);
  const [userBestScore, setUserBestScore] = useState(null);
  const [userRecentScore, setUserRecentScore] = useState(null);
  const [isLeaderboardLoading, setIsLeaderboardLoading] = useState(false);
  const [userRank, setUserRank] = useState(null);

  // **Level Outcome Management**
  const [levelOutcome, setLevelOutcome] = useState(null);
  const [finalLevelOutcome, setFinalLevelOutcome] = useState(null);

  // **Touch Interaction**
  const [touchTimer, setTouchTimer] = useState(null);
  const [lastTouchCell, setLastTouchCell] = useState(null);

  // **User Information**
  const [username, setUsername] = useState(null);

  // **Current Level Data**
  const currentLevel = levels[currentLevelIndex];

  // **Initialize Username on Component Mount**
  useEffect(() => {
    async function initializeUsername() {
      try {
        const name = await getOrCreateUsername();
        setUsername(name);
        console.log('Username initialized:', name); // Debugging
      } catch (error) {
        console.error('Error initializing username:', error);
      }
    }
    initializeUsername();
  }, []);

  // **Initialize the Game Board**
  const initializeBoard = useCallback(() => {
    const { rows, cols } = currentLevel;
    let newBoard = [];
    for (let r = 0; r < rows; r++) {
      let row = [];
      for (let c = 0; c < cols; c++) {
        row.push({
          x: r,
          y: c,
          isDeal: false,
          isRevealed: false,
          neighbor: 0,
          isFlagged: false,
          isQuestion: false,
        });
      }
      newBoard.push(row);
    }
    setBoard(newBoard);
    setFlags(0);
    setGameOver(false);
    setGameWon(false);
    setFirstClick(true);
    setIsTimerActive(false);
    setUserPosition(null);
    setUserTime(null);
  }, [currentLevel]);

  // **Calculate Neighboring Deals for Each Cell**
  const calculateNeighbors = (board) => {
    const { rows, cols } = currentLevel;
    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < cols; c++) {
        if (!board[r][c].isDeal) {
          let dealCount = 0;
          for (let i = -1; i <= 1; i++) {
            for (let j = -1; j <= 1; j++) {
              const newRow = r + i;
              const newCol = c + j;
              if (
                newRow >= 0 &&
                newRow < rows &&
                newCol >= 0 &&
                newCol < cols &&
                board[newRow][newCol].isDeal
              ) {
                dealCount++;
              }
            }
          }
          board[r][c].neighbor = dealCount;
        }
      }
    }
    return board;
  };

  // **Place Deals on the Board Excluding the First Click Area**
  const placeDeals = (board, excludeX, excludeY) => {
    const { deals, rows, cols } = currentLevel;
    let dealsPlaced = 0;
    while (dealsPlaced < deals) {
      const r = Math.floor(Math.random() * rows);
      const c = Math.floor(Math.random() * cols);
      if (
        !board[r][c].isDeal &&
        !(Math.abs(r - excludeX) <= 1 && Math.abs(c - excludeY) <= 1)
      ) {
        board[r][c].isDeal = true;
        dealsPlaced++;
      }
    }
    return calculateNeighbors(board);
  };

  // **Reveal a Cell and Its Neighbors Recursively if Necessary**
  const revealCell = (x, y, updatedBoard = [...board]) => {
    const { rows, cols } = currentLevel;
    if (
      x < 0 ||
      x >= rows ||
      y < 0 ||
      y >= cols ||
      updatedBoard[x][y].isRevealed ||
      updatedBoard[x][y].isFlagged
    ) {
      return updatedBoard;
    }

    updatedBoard[x][y].isRevealed = true;

    if (updatedBoard[x][y].neighbor === 0) {
      for (let i = -1; i <= 1; i++) {
        for (let j = -1; j <= 1; j++) {
          if (i !== 0 || j !== 0) {
            updatedBoard = revealCell(x + i, y + j, updatedBoard);
          }
        }
      }
    }

    return updatedBoard;
  };

  // **Handle Left Click on a Cell**
  const handleClick = (x, y) => {
    if (gameOver || gameWon || gameState !== 'playing') return;

    let updatedBoard = [...board];
    const cell = updatedBoard[x][y];

    if (cell.isRevealed || cell.isFlagged) return;

    if (firstClick) {
      updatedBoard = placeDeals(updatedBoard, x, y);
      setFirstClick(false);
      setIsTimerActive(true);
    }

    if (cell.isDeal) {
      // Reveal all deals
      for (let r = 0; r < currentLevel.rows; r++) {
        for (let c = 0; c < currentLevel.cols; c++) {
          if (updatedBoard[r][c].isDeal) {
            updatedBoard[r][c].isRevealed = true;
          }
        }
      }
      setBoard(updatedBoard);
      setGameOver(true);
      setIsTimerActive(false);
      submitScore(currentLevel.level, timer, 'lost');
      return;
    }

    // Reveal the clicked cell
    updatedBoard = revealCell(x, y, updatedBoard);
    setBoard(updatedBoard);

    // Check for Win Condition
    const { rows, cols, deals } = currentLevel;
    let safeRevealed = 0;
    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < cols; c++) {
        if (!updatedBoard[r][c].isDeal && updatedBoard[r][c].isRevealed) {
          safeRevealed++;
        }
      }
    }

    if (safeRevealed === rows * cols - deals) {
      setGameWon(true);
      setIsTimerActive(false);
      if (currentLevelIndex + 1 >= levels.length) {
        // Final Level Completed
        setFinalLevelOutcome(currentLevel.level);
        setTimeout(() => {
          setFinalLevelOutcome(null);
          submitScore(currentLevel.level, timer, 'won');
        }, 3000);
      } else {
        // Advance to Next Level
        revealAllDeals();
        setLevelOutcome(currentLevel.level);
      }
    }
  };

  // **Handle Right Click (Flagging) on a Cell**
  const handleRightClick = (x, y) => {
    if (gameOver || gameWon || gameState !== 'playing') return;

    const updatedBoard = [...board];
    const cell = updatedBoard[x][y];

    if (cell.isRevealed) return;

    if (!cell.isFlagged && !cell.isQuestion) {
      cell.isFlagged = true;
      setFlags((prevFlags) => prevFlags + 1);
    } else if (cell.isFlagged && !cell.isQuestion) {
      cell.isFlagged = false;
      cell.isQuestion = true;
      setFlags((prevFlags) => prevFlags - 1);
    } else if (cell.isQuestion) {
      cell.isQuestion = false;
    }

    setBoard(updatedBoard);
  };

  // **Timer Effect**
  useEffect(() => {
    let interval = null;
    if (isTimerActive && !gameOver && !gameWon) {
      interval = setInterval(() => {
        setTimer((prev) => parseFloat((prev + 0.01).toFixed(2)));
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, gameOver, gameWon]);

  // **Submit Score to Firebase**
  const submitScore = async (level, time, outcome) => {
    try {
      const username = await getOrCreateUsername(); // Ensure this function exists and works correctly
      if (!username) {
        throw new Error('Username is undefined or null.');
      }
      const leaderboardRef = collection(db, 'leaderboard');
      await addDoc(leaderboardRef, {
        username: username,
        level: level,
        time: time,
        outcome: outcome,
        timestamp: serverTimestamp(),
      });
      console.log('Score submitted successfully!');
      await fetchLeaderboard(); // Fetch updated leaderboard
      await fetchUserBestAndRecentScores(); // Fetch user's best and recent scores
      setGameState('leaderboard'); // Navigate to leaderboard after submitting score
    } catch (error) {
      console.error('Error submitting score:', error);
      alert('Failed to submit your score. Please try again.');
    }
  };

  // **Fetch Top 10 Leaderboard Entries**
  const fetchLeaderboard = useCallback(async () => {
    setIsLeaderboardLoading(true);
    try {
      const leaderboardRef = collection(db, 'leaderboard');
      const q = query(
        leaderboardRef,
        orderBy('level', 'desc'),
        orderBy('time', 'asc'),
        limit(10)
      );

      const querySnapshot = await getDocs(q);
      const top10 = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        top10.push({ 
          username: data.username, 
          level: data.level, 
          time: data.time 
        });
      });

      setLeaderboard(top10);
      console.log('Fetched Leaderboard:', top10); // Debugging
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      alert('Failed to fetch leaderboard. Please try again later.');
    } finally {
      setIsLeaderboardLoading(false);
    }
  }, []);

  // **Fetch User's Best and Recent Scores**
  const fetchUserBestAndRecentScores = useCallback(async () => {
    if (!username) {
      console.warn('Username is not set. Cannot fetch user scores.');
      return;
    }

    try {
      const leaderboardRef = collection(db, 'leaderboard');

      // **Fetch Best Score: Highest Level & Lowest Time**
      const bestScoreQuery = query(
        leaderboardRef,
        where('username', '==', username),
        orderBy('level', 'desc'),
        orderBy('time', 'asc'),
        limit(1)
      );

      const bestScoreSnapshot = await getDocs(bestScoreQuery);
      if (!bestScoreSnapshot.empty) {
        const bestData = bestScoreSnapshot.docs[0].data();
        setUserBestScore({
          level: bestData.level,
          time: bestData.time,
        });
        console.log('Fetched Best Score:', bestData); // Debugging
      } else {
        setUserBestScore(null);
        console.log('No Best Score Found for User:', username); // Debugging
      }

      // **Fetch Recent Score: Most Recent Game**
      const recentScoreQuery = query(
        leaderboardRef,
        where('username', '==', username),
        orderBy('timestamp', 'desc'),
        limit(1)
      );

      const recentScoreSnapshot = await getDocs(recentScoreQuery);
      if (!recentScoreSnapshot.empty) {
        const recentData = recentScoreSnapshot.docs[0].data();
        setUserRecentScore({
          level: recentData.level,
          time: recentData.time,
          outcome: recentData.outcome,
          timestamp: recentData.timestamp,
        });
        console.log('Fetched Recent Score:', recentData); // Debugging
      } else {
        setUserRecentScore(null);
        console.log('No Recent Score Found for User:', username); // Debugging
      }

      // **Determine User's Rank in Leaderboard**
      const rankQuery = query(
        leaderboardRef,
        orderBy('level', 'desc'),
        orderBy('time', 'asc'),
        where('username', '==', username)
      );

      const rankSnapshot = await getDocs(rankQuery);
      let rank = 1;
      let found = false;
      rankSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.username === username) {
          setUserRank(rank);
          found = true;
        }
        rank++;
      });

      if (!found) {
        setUserRank(null);
        console.log('User Rank not found:', username); // Debugging
      } else {
        console.log('User Rank:', rank - 1); // Debugging
      }

    } catch (error) {
      console.error('Error fetching user scores:', error);
      alert('Failed to fetch your scores. Please try again later.');
    }
  }, [username]);

  // **Handle Viewing Leaderboard from Welcome Screen**
  const handleViewLeaderboard = async () => {
    await fetchLeaderboard();
    await fetchUserBestAndRecentScores();
    setGameState('leaderboard');
  };

  // **Fetch Leaderboard and User Scores When Leaderboard is Viewed**
  useEffect(() => {
    if (gameState === 'leaderboard') {
      fetchLeaderboard();
      fetchUserBestAndRecentScores();
    }
  }, [gameState, fetchLeaderboard, fetchUserBestAndRecentScores]);

  // **Reveal All Deals (Used When Game is Won)**
  const revealAllDeals = () => {
    let updatedBoard = [...board];
    for (let r = 0; r < currentLevel.rows; r++) {
      for (let c = 0; c < currentLevel.cols; c++) {
        if (updatedBoard[r][c].isDeal) {
          updatedBoard[r][c].isRevealed = true;
        }
      }
    }
    setBoard(updatedBoard);
  };

  // **Move to Next Level**
  const moveToNextLevel = () => {
    if (currentLevelIndex + 1 < levels.length) {
      setCurrentLevelIndex((prevIndex) => prevIndex + 1);
      initializeBoard();
      setGameState('playing');
      setLevelOutcome(null);
    }
  };

  // **Proceed to Next Level After Outcome**
  const proceedToNextLevel = () => {
    setLevelOutcome(null);
    moveToNextLevel();
  };

  // **Start a New Game (Reset to Welcome Screen)**
  const startNewGame = () => {
    setGameState('welcome');
    setCurrentLevelIndex(0);
    setTimer(0);
    initializeBoard();
  };

  // **Initialize Board When Playing Starts or Level Changes**
  useEffect(() => {
    if (gameState === 'playing') {
      initializeBoard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLevelIndex]);

  // **Handle Touch Interactions for Mobile Devices**
  const handleTouchStart = (x, y) => {
    setLastTouchCell({ x, y });
    const timerId = setTimeout(() => {
      handleRightClick(x, y);
    }, 500); // 500ms long press
    setTouchTimer(timerId);
  };

  const handleTouchEnd = (x, y) => {
    clearTimeout(touchTimer);
    setTouchTimer(null);
    if (lastTouchCell && lastTouchCell.x === x && lastTouchCell.y === y) {
      handleClick(x, y);
    }
    setLastTouchCell(null);
  };

  const handleTouchMove = () => {
    clearTimeout(touchTimer);
    setTouchTimer(null);
    setLastTouchCell(null);
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gradient-to-b from-gray-100 to-blue-100 p-8">
      {/* **Welcome Screen** */}
      {gameState === 'welcome' && (
        <WelcomeScreen
          onStart={() => {
            setGameState('playing');
            initializeBoard();
          }}
          onViewLeaderboard={handleViewLeaderboard} // Handler for viewing leaderboard
          username={username} // Pass username to display on welcome screen
        />
      )}

      {/* **Gameplay Screen** */}
      {gameState === 'playing' && (
        <div className="flex flex-col items-center w-full max-w-4xl">
          {/* **Header Component** */}
          <Header
            gameOver={gameOver}
            onReset={() => {
              initializeBoard();
              setGameState('playing');
            }}
            timer={timer}
            remainingDeals={currentLevel.deals - flags}
            currentLevel={currentLevel.level}
          />

          {/* **Game Board Grid** */}
          <div
            className="grid mt-6 p-2 border-4 border-blue-500 rounded-lg shadow-lg bg-white"
            style={{
              gridTemplateColumns: `repeat(${currentLevel.cols}, 1fr)`,
              gridTemplateRows: `repeat(${currentLevel.rows}, 1fr)`,
              width: `${currentLevel.cols * 50}px`,
              height: `${currentLevel.rows * 50}px`,
            }}
          >
            {board.map((row, rowIndex) =>
              row.map((cell, cellIndex) => (
                <Cell
                  key={`${rowIndex}-${cellIndex}`}
                  cell={cell}
                  onClick={handleClick}
                  onRightClick={handleRightClick}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                  onTouchMove={handleTouchMove}
                />
              ))
            )}
          </div>

          {/* **Level Outcome Modal** */}
          {levelOutcome && (
            <LevelOutcome 
              level={levelOutcome} 
              onContinue={proceedToNextLevel} 
              className="mt-5"
            />
          )}

          {/* **Game Over or Game Won Message** */}
          {(gameOver || (gameWon && currentLevelIndex + 1 >= levels.length)) && (
            <div className="mt-6 flex flex-col items-center space-y-4">
              {gameOver && (
                <div className="text-red-600 font-bold text-2xl p-4 bg-white shadow-lg rounded-lg">
                  Game Over!
                </div>
              )}
              {gameWon && currentLevelIndex + 1 >= levels.length && (
                <div className="text-green-600 font-bold text-2xl p-4 bg-white shadow-lg rounded-lg">
                  Congratulations! You've completed all levels!
                </div>
              )}
              <div className="text-gray-100 font-bold bg-gray-800 px-6 py-3 rounded-lg shadow-lg">
                Flags: {flags}/{currentLevel.deals}
              </div>
            </div>
          )}
        </div>
      )}

      {/* **Leaderboard Screen** */}
      {gameState === 'leaderboard' && !finalLevelOutcome && (
        <Leaderboard
          leaderboard={leaderboard}
          userPosition={userPosition}
          userTime={userTime}
          currentLevel={currentLevel.level}
          onPlayAgain={startNewGame}
          gameOutcome={gameOver ? 'lost' : 'won'}
          userRank={userRank}
          username={username}
          userBestScore={userBestScore} // Pass user's best score
          userRecentScore={userRecentScore} // Pass user's recent score
        />
      )}

      {/* **Loading Indicator for Leaderboard Fetching** */}
      {isLeaderboardLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="flex flex-col items-center">
            <TailSpin color="#00BFFF" height={80} width={80} />
            <p className="mt-4 text-white text-lg">Fetching Leaderboard...</p>
          </div>
        </div>
      )}

      {/* **Final Level Outcome Modal** */}
      {finalLevelOutcome && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <LevelOutcome 
            level={finalLevelOutcome} 
            onContinue={() => {}} // Empty function as it will auto-proceed
          />
        </div>
      )}
    </div>
  );

};

export default GameBoard;
