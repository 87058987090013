// src/components/Header.jsx

import React from 'react';
import smileyImg from '../assets/images/smiley.png';
import frownImg from '../assets/images/frown.png';

const Header = ({ gameOver, onReset, timer, remainingDeals, currentLevel }) => {
  return (
    <header className="bg-blue-700 text-white py-6 flex items-center justify-between px-8 w-full max-w-4xl rounded-lg shadow-md">
      <div className="flex items-center space-x-3">
        <span className="text-xl font-bold">Level:</span>
        <span className="text-xl">{currentLevel}</span>
      </div>

      <button onClick={onReset} className="focus:outline-none transform hover:scale-110 transition-transform duration-200">
        <img
          src={gameOver ? frownImg : smileyImg}
          alt={gameOver ? 'Game Over' : 'Reset'}
          className="w-16 h-16"
        />
      </button>

      <div className="flex items-center space-x-3">
        <span className="text-xl font-bold">Time:</span>
        <span className="text-xl">{timer.toFixed(2)}s</span>
      </div>
    </header>
  );
};

export default Header;
