// /src/firebase/firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAnalytics } from "firebase/analytics"; // Import Analytics

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpFBKUlXgxYDKvwxbUc3qVpyx2jm9omVw",
  authDomain: "dealsweeper-app.firebaseapp.com",
  projectId: "dealsweeper-app",
  storageBucket: "dealsweeper-app.appspot.com",
  messagingSenderId: "629830212329",
  appId: "1:629830212329:web:408045df86c030d2f0ee78",
  measurementId: "G-YE7K2001X6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore
const analytics = getAnalytics(app); // Initialize Analytics

window.gtag('config', 'G-YE7K2001X6', { 'debug_mode': true });

export { app, db, analytics };
