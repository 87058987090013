// src/components/WelcomeScreen.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Briefcase, ArrowRight, Star, User } from 'lucide-react';

const WelcomeScreen = ({ onStart, onViewLeaderboard, username }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-100 to-blue-200 p-4">
      <div className="max-w-2xl w-full bg-white rounded-lg shadow-xl p-8 space-y-6">
        <h1 className="text-4xl font-bold text-blue-600 text-center mb-4">Welcome to Dealsweeper!</h1>

        {username ? (
          <div className="flex items-center justify-center space-x-2 bg-gray-100 p-3 rounded-lg">
            <User className="text-blue-500" />
            <span className="text-lg font-semibold text-gray-700">Your Username: {username}</span>
          </div>
        ) : (
          <div className="text-red-500 text-center">Failed to load username. Please refresh the page.</div>
        )}

        <div className="space-y-4">
          <p className="text-lg text-gray-700 text-center">
            Find all the deals first, before "closing" any.  Move to the next level once all deals are found. Rise through the ranks to become a master salesperson!
          </p>

          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded">
            <h2 className="text-xl font-semibold text-blue-700 mb-2">How to Play:</h2>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>Navigate the grid to find all deals</li>
              <li>Numbers signify how many deals are touching that tile</li>
              <li>Left-click to reveal tiles</li>
              <li>Right-click or long-press to flag suspected deals</li>
            </ul>
          </div>

          <div className="flex items-center justify-center space-x-2 text-gray-700">
            <Briefcase className="text-blue-500" />
            <span>Complete 8 levels</span>
            <ArrowRight className="text-green-500" />
            <span>Become a Master Salesperson</span>
            <Star className="text-yellow-500" />
          </div>

          <p className="text-sm text-gray-600 italic text-center">
            Each level adds more deals, increasing the challenge!
          </p>
        </div>

        <div className="flex flex-col items-center justify-center space-y-4 mt-6">
          <button
            onClick={onStart}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            Start Your Sales Journey!
          </button>

          {/* New "View Leaderboard" Button */}
          <button
            onClick={onViewLeaderboard}
            className="w-full flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            {/* Removed LeaderboardIcon as the image is missing */}
            View Leaderboard
          </button>
        </div>
      </div>
    </div>
  );
};

WelcomeScreen.propTypes = {
  onStart: PropTypes.func.isRequired,
  onViewLeaderboard: PropTypes.func.isRequired,
  username: PropTypes.string,
};

export default WelcomeScreen;
