// src/components/Leaderboard.jsx

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAnalytics, logEvent } from 'firebase/analytics'; // Import analytics
import { app } from '../firebase/firebase'; // Ensure Firebase app is correctly imported

const Leaderboard = ({
  leaderboard,
  userPosition,
  userTime,
  currentLevel,
  onPlayAgain,
  gameOutcome,
  username,
  userBestScore,
  userRecentScore,
}) => {

  // Log the event when the component is mounted (when the leaderboard is shown)
  useEffect(() => {
    const analytics = getAnalytics(app); // Initialize Analytics
    logEvent(analytics, 'leaderboard_view', {
      user_name: username,  // Custom parameter, could be user's name
      level: currentLevel,  // Track the current level
      position: userPosition // Track user's position in leaderboard
    });
  }, [currentLevel, userPosition, username]);

  return (
    <div className="flex flex-col items-center space-y-6 p-4 max-w-3xl mx-auto">
      <h2 className="text-3xl font-bold text-blue-600">Leaderboard</h2>

      {/* Display Username in Italics */}
      {username && (
        <p className="text-gray-600 italic mb-4">Username: {username}</p>
      )}

      {/* User's Best Score Section */}
      {userBestScore && (
        <div className="w-full bg-white rounded-lg shadow-md p-4">
          <h3 className="text-2xl font-semibold text-blue-600 mb-2">Your Best Score</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center">
              <span className="font-semibold text-blue-600">Level:</span>
              <span className="ml-2 text-gray-700">{userBestScore.level}</span>
            </div>
            <div className="flex items-center">
              <span className="font-semibold text-blue-600">Time:</span>
              <span className="ml-2 text-gray-700">{userBestScore.time.toFixed(2)} s</span>
            </div>
          </div>
        </div>
      )}

      {/* User's Recent Score Section */}
      {userRecentScore && (
        <div className="w-full bg-white rounded-lg shadow-md p-4">
          <h3 className="text-2xl font-semibold text-blue-600 mb-2">Your Most Recent Game</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center">
              <span className="font-semibold text-blue-600">Level:</span>
              <span className="ml-2 text-gray-700">{userRecentScore.level}</span>
            </div>
            <div className="flex items-center">
              <span className="font-semibold text-blue-600">Time:</span>
              <span className="ml-2 text-gray-700">{userRecentScore.time.toFixed(2)} s</span>
            </div>
            <div className="flex items-center col-span-2">
              <span className="font-semibold text-blue-600">Outcome:</span>
              <span className={`ml-2 text-gray-700 ${userRecentScore.outcome === 'won' ? 'text-green-600' : 'text-red-600'}`}>
                {userRecentScore.outcome === 'won' ? 'Won' : 'Lost'}
              </span>
            </div>
          </div>
        </div>
      )}

      {/* Display Outcome Message */}
      {gameOutcome === 'lost' && (
        <div className="text-red-600 font-bold text-xl">Better luck next time!</div>
      )}
      {gameOutcome === 'won' && currentLevel >= 8 && (
        <div className="text-green-600 font-bold text-xl">Congratulations! You've completed all levels!</div>
      )}
      {gameOutcome === 'won' && currentLevel < 8 && (
        <div className="text-green-600 font-bold text-xl">Great job!</div>
      )}

      {/* Leaderboard Table */}
      <div className="w-full overflow-x-auto bg-white rounded-lg shadow-md p-4">
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-4 border-b">Rank</th>
              <th className="py-2 px-4 border-b">Username</th>
              <th className="py-2 px-4 border-b">Level</th>
              <th className="py-2 px-4 border-b">Time (s)</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((entry, index) => {
              const isUserEntry = username && entry.username === username;
              const rowStyle = isUserEntry
                ? 'bg-yellow-200 font-bold'
                : index % 2 === 0
                ? 'bg-gray-100'
                : 'bg-white';
              return (
                <tr key={`${entry.username}-${entry.level}-${entry.time}`} className={rowStyle}>
                  <td className="py-2 px-4 border-b text-center">{index + 1}</td>
                  <td className="py-2 px-4 border-b">{entry.username || 'Anonymous'}</td>
                  <td className="py-2 px-4 border-b text-center">{entry.level}</td>
                  <td className="py-2 px-4 border-b text-center">{entry.time.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Congrats or Encouragement Message */}
      {userPosition && userPosition <= 10 && (
        <div className="text-green-600 font-bold text-xl mt-4">
          Congrats! You made it to the Top 10!
        </div>
      )}

      {/* Play Again Button */}
      <button
        onClick={onPlayAgain}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded mt-4 transition duration-300 ease-in-out transform hover:scale-105"
      >
        Play Again
      </button>
    </div>
  );

};

Leaderboard.propTypes = {
  leaderboard: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      time: PropTypes.number.isRequired,
    })
  ).isRequired,
  userPosition: PropTypes.number,
  userTime: PropTypes.number,
  currentLevel: PropTypes.number.isRequired,
  onPlayAgain: PropTypes.func.isRequired,
  gameOutcome: PropTypes.oneOf(['won', 'lost']).isRequired,
  username: PropTypes.string.isRequired,
  userBestScore: PropTypes.shape({
    level: PropTypes.number,
    time: PropTypes.number,
  }),
  userRecentScore: PropTypes.shape({
    level: PropTypes.number,
    time: PropTypes.number,
    outcome: PropTypes.string,
    timestamp: PropTypes.object,
  }),
};

export default Leaderboard;
