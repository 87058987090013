import { db } from '../firebase/firebase';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';

const colors = ['Red', 'Blue', 'Green', 'Yellow', 'Purple', 'Pink', 'Orange', 'Teal', 'Brown', 'Black', 'White', 'Gray', 'Gold', 'Silver', 'Cyan',];
const animals = ['Monkey', 'Elephant', 'Lion', 'Giraffe', 'Zebra', 'Penguin', 'Kangaroo', 'Koala', 'Spider', 'Tiger', 'Cheetah', 'Rhino', 'Hippo', 'Dolphin'];

function generateUsername() {
  const color = colors[Math.floor(Math.random() * colors.length)];
  const animal = animals[Math.floor(Math.random() * animals.length)];
  const number = Math.floor(Math.random() * 100);
  return `${color}${animal}${number}`;
}

async function isUsernameTaken(username) {
  const usernamesRef = collection(db, 'usernames');
  const q = query(usernamesRef, where('username', '==', username));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
}

export async function getOrCreateUsername() {
  let username = localStorage.getItem('username');
  if (!username) {
    let newUsername;
    do {
      newUsername = generateUsername();
    } while (await isUsernameTaken(newUsername));

    username = newUsername;
    localStorage.setItem('username', username);

    // Store the username in Firebase
    const usernamesRef = collection(db, 'usernames');
    await addDoc(usernamesRef, { username });
  }
  return username;
}

export function getCurrentUsername() {
  return localStorage.getItem('username');
}