// /src/components/Cell.jsx
import React from 'react';
// Import images
import coveredImg from '../assets/images/covered.png';
import uncoveredImg from '../assets/images/uncovered.png';
import flagImg from '../assets/images/flag.png';
import questionMarkImg from '../assets/images/question-mark.png';
import moneyGreenImg from '../assets/images/money-green.png'; // Updated image
import oneImg from '../assets/images/1.png';
import twoImg from '../assets/images/2.png';
import threeImg from '../assets/images/3.png';
import fourImg from '../assets/images/4.png';
import fiveImg from '../assets/images/5.png';
import sixImg from '../assets/images/6.png';
import sevenImg from '../assets/images/7.png';
import eightImg from '../assets/images/8.png';

const numberImages = {
  1: oneImg,
  2: twoImg,
  3: threeImg,
  4: fourImg,
  5: fiveImg,
  6: sixImg,
  7: sevenImg,
  8: eightImg,
};

const Cell = ({ cell, onClick, onRightClick, onTouchStart, onTouchEnd, onTouchMove }) => {
  // Determine which image to display based on cell state
  const getImage = () => {
    if (!cell.isRevealed) {
      if (cell.isFlagged) return flagImg;
      if (cell.isQuestion) return questionMarkImg;
      return coveredImg;
    } else {
      if (cell.isDeal) return moneyGreenImg; // Updated image reference
      if (cell.neighbor > 0) return numberImages[cell.neighbor];
      return uncoveredImg;
    }
  };

  const handleTouchStart = (e) => {
    e.preventDefault();  // Prevent default touch behavior
    onTouchStart(cell.x, cell.y);
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();  // Prevent default touch behavior
    onTouchEnd(cell.x, cell.y);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();  // Prevent default touch behavior
    onTouchMove();
  };

  // In Cell.jsx
  return (
    <div
      className={`no-select relative w-full h-full bg-gray-300 flex items-center justify-center cursor-pointer hover:bg-gray-400 ${
        cell.isRevealed ? 'bg-gray-200' : ''
      } rounded transition-all duration-200`}
      onClick={() => onClick(cell.x, cell.y)}
      onContextMenu={(e) => {
        e.preventDefault();
        onRightClick(cell.x, cell.y);
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchCancel={handleTouchEnd}
    >
      <img 
        src={getImage()} 
        alt="cell" 
        className="absolute w-full h-full object-contain" 
        draggable="false"
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  );
};

export default Cell;